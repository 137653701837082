import React from 'react'
import Helmet from 'react-helmet'

function IndeedPage() {
	const title = 'Indeed 2022'

	return (
		<>
			<Helmet htmlAttributes={{ metaLang: 'en' }} title={title}>
				<link rel="icon" type="image/png" href="/images/avatar.png" sizes="16x16" />
			</Helmet>
			<h1>Indeed 2022</h1>
		</>
	)
}

export default IndeedPage
